//Solución dada por https://v5.reactrouter.com/web/guides/scroll-restoration

import { useEffect } from "react";
import { useLocation } from "react-router-dom";


/**Esta función se encarga de redirigir la vista del usuario a la parte de arriba de la pantalla cuando este cambia de página.
 * 
 * @returns 
 */
export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    irArriba();
  }, [pathname]);

  const irArriba:any=()=>{
    window.scrollTo(0, 0);
  }
  return null;
}