import { logEvent } from "firebase/analytics";
import DocumentMeta from "react-document-meta";
import { analytics } from "../Firebase";
import "./Contacto.css"

function Contacto() {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "Contacto",
        firebase_screen_class: "Contacto"
    });

    const meta = {
        title: 'Fausto Sánchez Hoya',
        description: 'En esta página encontrarás cómo contactar con Fausto Sánchez Hoya, desarrollador fullstack.',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,fullstack,javascript,.net,programming,html'
            }
        }
    }

    return (
        <DocumentMeta {...meta}>
        <div className="pagina">
            <main>
                <h1>Contacto</h1>
                <hr />
                <div className="contactoContainer">
                    <p>Puedes contactarme a través de mi correo electrónico: 
                        <br />
                        <br />
                        <strong>faustosanchezhoya@gmail.com</strong>
                        <br />
                        <br />
                        o también a través de mi LinkedIn <a href="https://www.linkedin.com/in/faustosh/">pulsando aquí</a>.
                    </p>

                </div>

            </main>
        </div>
        </DocumentMeta>
    );
}

export default Contacto;