import { logEvent } from "firebase/analytics";
import DocumentMeta from "react-document-meta";
import { analytics } from "../Firebase";
import "./Proyectos.css"

function Proyectos() {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "Proyectos",
        firebase_screen_class: "Proyectos"
    });

    const meta = {
        title: 'Fausto Sánchez Hoya',
        description: 'Mira los últimos proyectos de desarrollo fullstack de Fausto Sánchez Hoya',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,fullstack,javascript,.net,programming,html'
            }
        }
    }

    return (
        <DocumentMeta {...meta}>
        <div className="pagina">
            <main>
                <h1>Proyectos</h1>
                <hr />
                <div className="listaProyectosContainer">
                    <div className="proyectoContainer">
                        <div className="proyectoTexto">
                            <h2 className="tituloProyecto">Aprende Algoritmos</h2>
                            <p>
                                Portal web destinado a facilitar el estudio de algoritmia en la programación. Cuenta con explicaciones
                                de diferentes algoritmos así como problemas para practicarlos y un simulador online que muestra su
                                funcionamiento gráficamente.
                                <br /><br />
                                Este proyecto se ha desarrollado utilizando React y Typescript como herramientas principales y Firebase para el backend.
                                <br /><br />
                                Puedes acceder a este proyecto <a href="http://aprendealgoritmos.web.app" target={"_blank"} rel="noreferrer">desde aquí</a>.
                            </p>
                        </div>
                        <div className="proyectoImagen">
                            <a href="http://aprendealgoritmos.web.app" target="_blank" rel="noreferrer" >
                                <img src={process.env.PUBLIC_URL + "/resources/imgProyectos/AprendeAlgoritmos.webp"} loading="lazy" alt="Proyecto Aprende Algoritmos" />
                            </a>
                        </div>
                    </div>
                    <hr />
                    <div className="proyectoContainer">
                        <div className="proyectoTexto">
                            <h2 className="tituloProyecto">Dormir en Madrid</h2>
                            <p>
                                Página web de referencia para la búsqueda de alojamiento y actividades en Madrid. Esta página fue
                                recomendada por la embajada ucraniana en su Facebook a raíz del conflicto con Rusia. <a href="https://www.facebook.com/permalink.php?story_fbid=4672152442879831&id=822986187796495" target={"_blank"} rel="noreferrer">(Enlace al post)</a>
                                <br /><br />
                                Este proyecto se ha desarrollado utilizando PHP para crear el tema de Wordpress y este último CMS para la gestión de la página.
                                <br /><br />
                                Puedes acceder a este proyecto <a href="https://dormirenmadrid.es/" target={"_blank"} rel="noreferrer">desde aquí</a>.
                            </p>
                        </div>
                        <div className="proyectoImagen">
                            <a href="https://dormirenmadrid.es/" target="_blank" rel="noreferrer">
                                <img src={process.env.PUBLIC_URL + "/resources/imgProyectos/Dormir.webp"} loading="lazy" alt="Proyecto dordmir en Madrid" />
                            </a>
                        </div>
                    </div>
                    <hr />
                    <div className="proyectoContainer">
                        <div className="proyectoTexto">
                            <h2 className="tituloProyecto">Advanced Project History</h2>
                            <p>
                                Extensión para Google Chrome que presenta una nueva experiencia de navegación creando un
                                historial basado en eventos, además de permitir añadir notas a cualquier página web. Todo esto
                                organizado en diferentes proyectos para, por ejemplo, facilitar los trabajos de investigación.
                                <br /><br />
                                Este proyecto se ha desarrollado utilizando HTML y CSS puros junto con Javascript y la librería de Firebase.
                                <br /><br />
                                Puedes acceder a este proyecto <a href="https://advancedprojecthistory.com/" target={"_blank"} rel="noreferrer">desde aquí</a>.
                            </p>
                        </div>
                        <div className="proyectoImagen">
                            <a href="https://advancedprojecthistory.com/" target="_blank" rel="noreferrer">
                                <img src={process.env.PUBLIC_URL + "/resources/imgProyectos/APH.webp"} loading="lazy" alt="Proyecto Advanced Project History" />
                            </a>
                        </div>
                    </div>
                    <hr />
                    <div className="proyectoContainer">
                        <div className="proyectoTexto">
                            <h2 className="tituloProyecto">Ninja Running</h2>
                            <p>
                                Juego de plataformas para dispositivos android en el que deberás ir avanzando por diferentes mapas
                                recogiendo monedas y evitando obstáculos. Este juego está disponible en Google Play.
                                <br /><br />
                                Este proyecto se ha desarrollado utilizando Unity junto con C#.
                                <br /><br />
                                Puedes acceder a este proyecto <a href="https://play.google.com/store/apps/details?id=com.OtsuafGames.NinjaRunning&hl=es&gl=US" target={"_blank"} rel="noreferrer">desde aquí</a>.
                            </p>
                        </div>
                        <div className="proyectoImagen">
                            <a href='https://play.google.com/store/apps/details?id=com.OtsuafGames.NinjaRunning&hl=es&gl=US' target="_blank" rel="noreferrer">
                                <img src={process.env.PUBLIC_URL + "/resources/imgProyectos/ninjaRunning.webp"} loading="lazy" alt="Proyecto Ninja Running" />
                            </a>
                        </div>
                    </div>
                    <hr />
                </div>
            </main>
        </div>
        </DocumentMeta>
    );
}

export default Proyectos;