import HeaderComputer from "./HeaderComputer";
import HeaderMobile from "./HeaderMobile";
import "./HeaderGlobal.css"

function HeaderGlobal() {
    return (
        <>
            <HeaderMobile />
            <HeaderComputer />
        </>
    )
}

export default HeaderGlobal;