import { logEvent } from "firebase/analytics";
import DocumentMeta from "react-document-meta";
import { analytics } from "../Firebase";
import "./Novelas.css"


function Novelas() {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "Novelas",
        firebase_screen_class: "Novelas"
    });

    const meta = {
        title: 'Fausto Sánchez Hoya',
        description: '¿Quieres saber quíen es Fausto Sánchez Hoya? Aquí encontrarás esa información sobre este desarrollador fullstack.',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,fullstack,javascript,.net,programming,html'
            }
        }
    }

    return (
        <DocumentMeta {...meta}>
            <div className="pagina">
                <main>
                    <h1>Recuerdos del futuro</h1>
                    <hr />
                    <div className="novelasContainer">
                        <div className="textoNovelas">
                            <p>
                                <strong>Recuerdos del futuro</strong> es un apasionante viaje a través de un mundo donde el pasado y el futuro se entrelazan en un misterioso baile. En este cautivador universo, cada vida se convierte en un libro, cada página una ventana a momentos que desafían la linealidad del tiempo.
                            </p>
                            <p>
                                Acompaña a Darian y Valia, dos canalizadores de recuerdos atrapados en un enigma que se despliega con cada página, mientras desentrañan los secretos que yacen en la intersección de sus vidas. Este libro te mantendrá al borde de tu asiento, explorando los límites de la existencia y la profundidad de las conexiones humanas, todo envuelto en un manto de misterio que te mantendrá adivinando hasta el final.
                            </p>
                            <p>
                                <strong>Recuerdos del Futuro</strong> es más que una novela, es un enigma envuelto en la belleza de la memoria. ¿Estás listo para abrir este libro y sumergirte en un viaje inolvidable?
                            </p>
                            <br />
                            <button className="botonesNovelas"><a className="botonConAnchor" href={process.env.PUBLIC_URL + "/resources/Recuerdos del futuro-Fausto Sanchez Hoya-Primer capitulo.pdf"}>Lee gratis el primer capítulo</a></button>
                            <br />
                            <button className="botonesNovelas"><a className="botonConAnchor" href="https://www.amazon.es/dp/B0CJL27BDG">Comprar en Amazon</a></button>
                        </div>
                        <div className="imagenNovelas">
                            <img src={process.env.PUBLIC_URL + "/resources/imagenNovelas/Recuerdosdelfuturoportada.webp"} loading="eager" alt="Recuerdos del futuro" />
                        </div>
                    </div>
                </main>
            </div>
        </DocumentMeta>
    );
}

export default Novelas;