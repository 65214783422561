import { logEvent } from "firebase/analytics";
import DocumentMeta from "react-document-meta";
import { analytics } from "../Firebase";
import "./Sobremi.css"


function Sobremi() {

    logEvent(analytics, 'screen_view', {
        firebase_screen: "Sobremi",
        firebase_screen_class: "Sobremi"
    });

    const meta = {
        title: 'Fausto Sánchez Hoya',
        description: '¿Quieres saber quíen es Fausto Sánchez Hoya? Aquí encontrarás esa información sobre este desarrollador fullstack.',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,fullstack,javascript,.net,programming,html'
            }
        }
    }

    return (
        <DocumentMeta {...meta}>
        <div className="pagina">
            <main>
                <h1>Sobre mí</h1>
                <hr />
                <div className="sobremiContainer">
                    <div className="textoSobremi">
                        <p>
                            Hola, si estás leyendo esto es que quieres conocerme. Permíteme presentarme, soy Fausto Sánchez Hoya, desarrollador fullstack con muchas ganas de seguir aprendiendo.
                            <br /><br />

                            📖Siempre he preferido contar las cosas a modo de historia así que déjame contarte la mía:
                            <br /><br />

                            Todo comenzó con un chico apasionado de todo el mundo tecnológico que, por devenires de la vida, acabó estudiando Ingeniería Informática en la USAL.
                            <br /><br />

                            💻Durante todo este tiempo me he caracterizado por cacharrear con los diferentes ámbitos de la informática, desde la electrónica hasta la IA, cuando en 2020 empecé un proyecto para poder ofrecer soluciones tecnológicas a diferentes empresas que se enfocaron sobre todo en el diseño y desarrollo web. Esto me permitió definir mejor mi vocación: generar herramientas, tanto software convencional como web-apps o cualquier parecido, que pudieran ayudar a las personas en algún ámbito.
                            <br /><br />

                            🧰Teniendo esto en mente he desarrollado otros proyectos a nivel personal entre los que se encuentran una extensión para facilitar los trabajos de investigación, un portal web para ayudar a encontrar alojamiento en Madrid o una herramienta online para ayudar al estudio de la algoritmia. De todos ellos puedo decir que me encuentro bastante orgulloso.
                            <br /><br />

                            Y finalmente nos encontramos en este momento. Si has llegado hasta aquí, ¿por qué no hablamos?
                        </p>
                    </div>
                    <div className="imagenSobremi">
                        <img src={process.env.PUBLIC_URL + "/resources/imagenSobremi/FaustoSanchezHoya.webp"} loading="eager" alt="Fausto Sánchez Hoya" />
                    </div>
                </div>
            </main>
        </div>
        </DocumentMeta>
    );
}

export default Sobremi;