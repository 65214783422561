import HeaderGlobal from './components/HeaderGlobal';
import "./App.css";
import { Link, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Sobremi from './components/Sobremi';
import Proyectos from './components/Proyectos';
import Contacto from './components/Contacto';
import Footer from './components/Footer';
import Privacidad from './components/Privacidad';
import Cookies from './components/Cookies';
import CookieConsent from 'react-cookie-consent';
import { setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { analytics } from './Firebase';
import ScrollToTop from './components/ScrollToTop';
import Novelas from './components/Novelas';
function App() {

  return (
    <>
      <ScrollToTop/>
      <HeaderGlobal/>
      <div className="content">
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/sobremi' element={<Sobremi/>}/>
            <Route path='/proyectos' element={<Proyectos/>}/>
            <Route path='/novelas' element={<Novelas/>}/>
            <Route path='/contacto' element={<Contacto/>}/> 
            <Route path='/politica-de-privacidad' element={<Privacidad/>}/> 
            <Route path='/politica-de-cookies' element={<Cookies/>}/> 

            <Route path='*' element={<Home/>}/>
        </Routes>
      </div>
      <Footer />
      <CookieConsent
        location="bottom"
        style={{background: "#606060", color: "#ffffff", fontSize: "18px" , fontFamily: "Amiri"}}
        buttonText="Aceptar"
        buttonStyle={{background: "#2DAFD0", color: "#ffffff", fontSize: "25px" , fontFamily: "Poppins", borderRadius: "10px"}}
        expires={365}
        enableDeclineButton={true}
        declineButtonText="Usar sólo las cookies necesarias"
        declineButtonStyle={{background: "#fdfdfd", color: "#2DAFD0", fontSize: "15px" , fontFamily: "Poppins", borderRadius: "10px", border: "1px solid #2DAFD0"}}
        onDecline={() => {   setAnalyticsCollectionEnabled(analytics, false); }}
        onAccept={() => {   setAnalyticsCollectionEnabled(analytics, true); }}
      >
        Esta página usa cookies para ofrecer un contenido de calidad. Puede saber más en nuestra <Link to='/politica-de-privacidad'>politica de privacidad.</Link>
      </CookieConsent>
    </>
  );
}

export default App;
