import { Link, useNavigate } from "react-router-dom";
import "./HeaderComputer.css";

function HeaderComputer() {

    const Nav=useNavigate();

    return (
        <header className="headerComputer">
            <div className="logoContainer">
                <button className="logoMenu" onClick={() => { Nav("/") }}>
                    <img src={process.env.PUBLIC_URL + "/resources/LogoSinTexto.svg"} alt="logo" />
                </button>
            </div>
            <nav className="computerMenu">
                <Link to={"/sobremi"}>Sobre mí</Link>
                <Link to={"/proyectos"}>Proyectos</Link>
                <Link to={"/novelas"}>Novelas</Link>
                <Link to={"/contacto"}>Contacto</Link>
            </nav>
        </header>
    )
}

export default HeaderComputer;