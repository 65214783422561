import { Link } from "react-router-dom";
import "./Footer.css"

function Footer() {
    return (
        <footer>
             <Link to={"/politica-de-privacidad"}>Privacidad</Link>
             <Link to={"/politica-de-cookies"}>Cookies</Link>
        </footer>
    );
}

export default Footer;