import { logEvent } from "firebase/analytics";
import { BiChevronsDown } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { analytics } from "../Firebase";
import DocumentMeta from 'react-document-meta';
import "./Home.css"

function Home() {

    const Nav = useNavigate();

    logEvent(analytics, 'screen_view', {
        firebase_screen: "Home",
        firebase_screen_class: "Home"
    });

    const meta = {
        title: 'Fausto Sánchez Hoya',
        description: 'En esta página encontrarás información sobre Fausto Sánchez Hoya, desarrollador fullstack.',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,fullstack,javascript,.net,programming,html'
            }
        }
    }

    

    return (
        <DocumentMeta {...meta} >
        <div className="pagina">
            <div className="presentacionContainer">
                <img src={process.env.PUBLIC_URL + "/resources/FotoPrincipal.webp"} alt="Imagen presentación" />
                <div className="bienvenidaSquare">
                    <span>Bienvenido...</span>
                </div>
                <div className="flechaAbajo">
                    <a href="#comienzoContenido"><BiChevronsDown className="icono" color="var(--blanco)" /></a>
                </div>
            </div>
            <main id="comienzoContenido">
                <h1>Fullstack Developer</h1>
                <hr />
                <div className="seccionHome">
                    <h2>Fausto Sánchez Hoya</h2>
                    <p>Hola, soy Fausto Sánchez Hoya, desarrollador fullstack y esta es mi página web.
                        Aquí encontrarás información sobre mí y sobre las diferentes cosas en las que trabajo. Desde los proyectos en los que he participado hasta las novelas que he escrito.
                        <br />
                        ¿Quieres saber más?
                    </p>
                    <button onClick={() => { Nav("/sobremi") }}>Sobre mí</button>
                </div>
                <hr />
                <div className="seccionHome">
                    <h2>Proyectos</h2>
                    <div className="proyectos">
                        <div className="proyecto">
                            <a href="http://aprendealgoritmos.web.app" target="_blank" rel="noreferrer">
                                <img src={process.env.PUBLIC_URL + "/resources/imgProyectos/AprendeAlgoritmos.webp"} loading="lazy" alt="Proyecto Aprende Algoritmos" />
                            </a>
                        </div>
                        <div className="proyecto">
                            <a href="https://dormirenmadrid.es/" target="_blank" rel="noreferrer">
                                <img src={process.env.PUBLIC_URL + "/resources/imgProyectos/Dormir.webp"} loading="lazy" alt="Proyecto dordmir en Madrid" />
                            </a>
                        </div>
                        <div className="proyecto">
                            <a href="https://advancedprojecthistory.com/" target="_blank" rel="noreferrer">
                                <img src={process.env.PUBLIC_URL + "/resources/imgProyectos/APH.webp"} loading="lazy" alt="Proyecto Advanced Project History" />
                            </a>
                        </div>
                        <div className="proyecto">
                            <a href='https://play.google.com/store/apps/details?id=com.OtsuafGames.NinjaRunning&hl=es&gl=US' target="_blank" rel="noreferrer">
                                <img src={process.env.PUBLIC_URL + "/resources/imgProyectos/ninjaRunning.webp"} loading="lazy" alt="Proyecto Ninja Running" />
                            </a>
                        </div>
                    </div>
                    <p>
                        Si quieres saber más sobre los proyectos que he realizado como desarrollador fullstack no dudes en ir a la página de los proyectos.
                    </p>
                    <button onClick={() => { Nav("/proyectos") }}>Sobre proyectos</button>
                </div>
            </main>

        </div>
        </DocumentMeta>
    )
}

export default Home;